<template>
  <v-card class="pa-4">
    <iframe
      :src="srcIframe" 
      frameborder="0" 
      height="425px"
      width="100%"
    ></iframe>
  </v-card>
</template>
<script>
export default {
  name: 'Tutorial',
  data() {
    return {
      srcIframe: 'https://mark.deploy.cbs.co.id/s/QoggWawYM',
    }
  },
}
</script>

<style scoped>
.theme--dark.v-card > .v-card__text,
.theme--dark.v-card > .v-card__subtitle {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
.bottom-action {
  display: none;
}

.loading-iframe {
  /* background: url(https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif) center center no-repeat; */
}

.loading-box {
  width: 276px;
  height: 277px;
}

@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
}
</style>
